import React from "react";
import { useApplicationContext } from "../../../contexts/useApplication";
import {
  formatBigNumber,
  getBurnXaitaByLevel,
  getEarnXaitaByLevel,
  getXaitaBurned,
} from "../../../utils";

const LevelBoard = ({ onCancel }: { onCancel?: () => void }) => {
  const { user } = useApplicationContext();
  return (
    <div className="relative">
      <img src="/images/level_bg.png" alt="err" />
      <div className="absolute top-0 w-full flex flex-col items-center">
        <h3 className="text-[16px] font-[400] uppercase text-center ml-[3rem] superLegendBoy mt-[1.5rem]">
          YOUR LEVEL
        </h3>
        <img src="/images/level0.png" className="mt-[1rem]" alt="err" />
        <div className="mt-[12px] ">
          <p className="text-[8px] font-[400] text-center superLegendBoy">
            YOU HAVE REACHED
          </p>
          <p className="uppercase text-[24px] font-[400] text-[#FE1319] text-center superLegendBoy mt-[4px]">
            level {user?.level || 0}
          </p>
        </div>

        <div className="mt-[11px] w-full px-[20px] text-[20px] ">
          <div className="grid grid-cols-2 items-start">
            <p className="font-[400] text-black text-[18px] ">
              Your total burned:
            </p>
            <div className="flex items-center gap-[6px]">
              <img
                src="/images/xaitama_coin.png"
                className="w-[17px]"
                alt="err"
              />
              <p>{formatBigNumber(getXaitaBurned(user?.level))} $XAITA</p>
            </div>
          </div>
          <div className="grid grid-cols-2 items-start mt-[6px]">
            <p className="font-[400] text-black text-[18px] ">
              Each turn, you’ll earn:
            </p>
            <div className="flex items-center gap-[6px]">
              <img
                src="/images/xaitama_coin.png"
                className="w-[17px]"
                alt="err"
              />
              <p>{getEarnXaitaByLevel(user?.level)} $XAITA</p>
            </div>
          </div>
        </div>

        {onCancel && (
          <button onClick={onCancel} className="inline-block mt-[11px]">
            <img src="/images/button_done.png" alt="err" />
          </button>
        )}
      </div>
    </div>
  );
};

export default LevelBoard;
