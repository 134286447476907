import React from "react";
import { Modal } from "antd";
import LevelBoard from "../LevelBoard";

interface ICustomModalProps extends React.ComponentProps<typeof Modal> {
  open: boolean;
  onCancel: () => void;
}

const ModalLevelUp = ({ open, onCancel }: ICustomModalProps) => {
  return (
    <div
      className={`fixed opacity-0 transition-all z-[-1] duration-300 ${
        open && "!z-[999] !opacity-100"
      } bg-[rgba(0,0,0,0.8)] w-full h-full  flex items-center justify-center `}
    >
      <div
        className={`mt-[10rem] transition-all  duration-300  ${open && "!mt-0"}`}
      >
        <LevelBoard onCancel={onCancel} />
      </div>
    </div>
  );
};

export default ModalLevelUp;
