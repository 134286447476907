import axios from "axios";

export const getUserProfile = async ({ ...params }) => {
  const url = `${process.env.REACT_APP_API_URL}/user/get-user`;
  const res = await axios.get(url, { params });
  const { data } = res;
  return data;
};

export const claimTurnTask = async ({ ...params }) => {
  const url = `${process.env.REACT_APP_API_URL}/task/claim-turn-tasks`;
  const res = await axios.get(url, { params });
  const { data } = res;
  return data;
};

export const claimXaita = async ({ ...params }) => {
  const url = `${process.env.REACT_APP_API_URL}/task/claim-xaita-ref`;
  const res = await axios.get(url, { params });
  const { data } = res;
  return data;
};

export const claimXaitaPlay = async ({ ...params }) => {
  const url = `${process.env.REACT_APP_API_URL}/user/claim-reward`;
  const res = await axios.get(url, { params });
  const { data } = res;
  return data;
};

export const playGame = async ({ ...params }) => {
  const url = `${process.env.REACT_APP_API_URL}/user/start-mint`;
  const res = await axios.get(url, { params });
  const { data } = res;
  return data;
};

export const levelUp = async ({ ...params }) => {
  const url = `${process.env.REACT_APP_API_URL}/user/coach`;
  const res = await axios.get(url, { params });
  const { data } = res;
  return data;
};

export const checkTask = async (body: any, params: any) => {
  const url = `${process.env.REACT_APP_API_URL}/task/check-tasks`;
  const res = await axios.post(url, body, { params });
  const { data } = res;
  return data;
};

export const getRank = async (body: any, params: any) => {
  const url = `${process.env.REACT_APP_API_URL}/user/get-rank`;
  // const url = `https://0bd47518-8000.asse.devtunnels.ms/xaitama-api/user/get-rank`;
  const res = await axios.post(url, body, { params });
  const { data } = res;
  return data;
};
