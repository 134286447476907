import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import { useApplicationContext } from "../../contexts/useApplication";
import { getBurnXaitaByLevel } from "../../utils";

const Footer = () => {
  const { user, assets } = useApplicationContext();
  const menu = [
    {
      imgUrl: "/images/footer/hotpot.png",
      imgUrlActive: "/images/footer/hotpot_active.png",
      href: "/",
      name: "hotpot",
    },
    {
      imgUrl: "/images/footer/rank.png",
      imgUrlActive: "/images/footer/rank_active.png",
      href: "/rank",
      name: "rank",
    },
    {
      imgUrl: "/images/footer/mission.png",
      imgUrlActive: "/images/footer/mission_active.png",
      href: "/mission",
      name: "mission",
    },
    {
      imgUrl: "/images/footer/dojo.png",
      imgUrlActive: "/images/footer/dojo_active.png",
      href: "#",
      name: "ATM",
    },
    {
      imgUrl: "/images/footer/profile_active.png",
      imgUrlActive: "/images/footer/profile_active.png",
      href: "/profile",
      name: "Level " + (user?.level || 0),
    },
  ];

  const location = useLocation();
  const handleOnclick = (e: any, imgUrl: any) => {
    if (imgUrl == "/images/footer/dojo.png")
      return toast.success("Coming soon!");
  };

  return (
    <div className="grid grid-cols-5 absolute bottom-0 pb-3 z-10 w-full items-end bg-[#DBF8FF] px-[3px] pt-[4px]">
      {menu.map((item: any, index: any) => (
        <div
          key={index}
          className="flex justify-center actionButton relative"
          onClick={(e) => {
            handleOnclick(e, item.imgUrl);
          }}
        >
          <Link to={item.href}>
            <img
              src={
                location.pathname === item.href
                  ? item.imgUrlActive
                  : item.imgUrl
              }
              className="cursor-pointer"
              alt="footer"
            />
            <p className="uppercase absolute bottom-[0.6rem] left-1/2 text-[16px] font-[400] translate-x-[-50%] whitespace-nowrap">
              {item.name}
            </p>
            {item.href == "/profile" &&
              Number(assets?.amount) > getBurnXaitaByLevel(user?.level) && (
                <img
                  className="absolute right-0 -top-2 w-5"
                  src="/images/level_up.gif"
                />
              )}
          </Link>
        </div>
      ))}
    </div>
  );
};
export default Footer;
