import React from "react";
import useBackButton from "../../hooks/useBackButton";
import { useApplicationContext } from "../../contexts/useApplication";
import ButtonExplore from "../../components/ButtonExplore";

const Dojo = () => {
  //useBackButton();
  const { WebApp } = useApplicationContext();

  return (
    <div className="bg-[#B7F1FE] h-full px-3 py-20 pb-28 overflow-scroll">
      <div className="relative w-full flex flex-col items-center justify-center gap-[4px] text-center">
        <div className="text-[20px] text-[#095c8b] superLegendBoy uppercase">
          Unleash your
        </div>
        <div className="text-[20px] text-[#095c8b] superLegendBoy uppercase">
          inner complex
        </div>
      </div>
      <div className="flex flex-col justify-center mt-4 gap-3">
        <div className="relative">
          <img src="/images/dojo/store.png" className="w-full" alt="err" />
          <div className="absolute right-6 top-6">
            <div className="relative w-full flex flex-col items-start justify-start gap-[4px]">
              <div className="relative superLegendBoy">STORE</div>
              <div className="w-[200px] relative text-[16px] inline-block">
                "Can I get enough meat for a hot pot that could satisfy a bored
                cyborg?"
              </div>
            </div>
          </div>
          <div className="absolute bottom-5 right-5">
            <ButtonExplore url="/">Explore</ButtonExplore>
          </div>
        </div>
        <div className="relative">
          <img src="/images/dojo/spin.png" className="w-full" alt="err" />
          <div className="absolute right-6 top-6">
            <div className="relative w-full flex flex-col items-start justify-start gap-[4px]">
              <div className="relative superLegendBoy">LUCKY WHEEL</div>
              <div className="w-[200px] relative text-[16px] inline-block">
                "Finally, a worthy opponent!" Give a Serious Spin, shattering
                the machine and sending prizes flying
              </div>
            </div>
          </div>
          <div className="absolute bottom-5 right-5">
            <ButtonExplore url="/">Explore</ButtonExplore>
          </div>
        </div>
        <div className="relative">
          <img src="/images/dojo/atm.png" className="w-full" alt="err" />
          <div className="absolute right-6 top-6">
            <div className="relative w-full flex flex-col items-start justify-start gap-[4px]">
              <div className="relative superLegendBoy">ATM</div>
              <div className="w-[200px] relative text-[16px] inline-block">
                One-punch withdrawal? Expect the ATM whimpered and spat out a
                wad of cash the size of Jupiter.
              </div>
            </div>
          </div>
          <div className="absolute bottom-5 right-5">
            <ButtonExplore url="/">Explore</ButtonExplore>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dojo;
