import IconLoading from "../assets/icon/IconLoading";

const ButtonCheck = ({ onClick, loading = false, title = "Check" }: any) => {
  return (
    <div
      onClick={onClick}
      className="flex actionButton justify-center relative cursor-pointer"
    >
      <img
        src={"/images/btn_check.png"}
        className="cursor-pointer"
        alt="header"
      />
      <div
        className={`absolute h-full z-10 text-[18px] uppercase w-full flex justify-center items-center m-auto inset-0 pb-1`}
      >
        {!loading ? title : <IconLoading />}
      </div>
    </div>
  );
};
export default ButtonCheck;
