import { PaginationProps, Table, TableProps } from "antd";
import NoData from "../NoData";
import React from "react";
import cx from "classnames";
// import IconPrev from "@/assets/icons/IconPrev";
// import IconNext from "@/assets/icons/IconNext";

const CustomTable = ({
  columns,
  dataSource,
  bordered = false,
  loading,
  scroll,
  className,
  pagination,
  ...props
}: TableProps<any>) => {
  const itemRender: PaginationProps["itemRender"] = (
    page,
    type,
    originalElement
  ) => {
    // if (type === "prev") {
    //   return (
    //     <div className="flex items-center h-full">
    //       <IconPrev />
    //     </div>
    //   );
    // }
    // if (type === "next") {
    //   return (
    //     <div className="flex items-center h-full">
    //       <IconNext />
    //     </div>
    //   );
    // }
    if (type === "page") {
      return (
        <div className="text-secondary text-base font-medium rounded-lg hover:bg-layer-focus w-full h-full flex items-center justify-center">
          {page}
        </div>
      );
    }
    return originalElement;
  };

  return (
    <Table
      className={cx("custom-table", className)}
      columns={columns}
      dataSource={dataSource}
      bordered={bordered}
      pagination={
        !pagination
          ? false
          : {
              ...pagination,
              itemRender: itemRender,
              className: "custom-pagination",
            }
      }
      loading={loading}
      locale={{
        emptyText: <NoData />,
      }}
      scroll={scroll}
      {...props}
    />
  );
};

export default CustomTable;
