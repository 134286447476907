import { useState, createContext, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { getUserProfile } from "../services/profile";
import { useWebApp } from "../hooks";

export const ApplicationContext = createContext({} as any);
export const useApplicationContext = () => useContext(ApplicationContext);

export function ApplicationProvider({ children }: any) {
  const [profile, setProfile] = useState<any>({});
  const [user, setUser] = useState<any>({});
  const [task, setTask] = useState<any>({});
  const [assets, setAssetsTask] = useState<any>({});
  const [ref, setRef] = useState<any>([]);
  const [reward, setReward] = useState<any>({});
  const WebApp = useWebApp();
  const [defaultParams, setDefaultParams] = useState<any>({});
  const handleGetProfile = async () => {
    try {
      const params = {
        auth_date: WebApp?.initDataUnsafe?.auth_date,
        query_id: WebApp?.initDataUnsafe?.query_id,
        user: JSON.stringify(WebApp?.initDataUnsafe?.user),
        hash: WebApp?.initDataUnsafe?.hash,
      };
      const res = await getUserProfile(params);
      if (res?.data) {
        setProfile(res?.data?.prop);
        setUser(res?.data?.user);
        setTask(res?.data?.tasks);
        setAssetsTask(res?.data?.asset);
        setRef(res?.data?.ref || []);
        setReward(res?.data?.reward[res?.data?.reward?.length - 1] || {});
      }
    } catch (ex: any) {
      toast.error(ex.response?.data?.message || ex.message);
    }
  };
  useEffect(() => {
    WebApp?.initDataUnsafe?.user?.id && handleGetProfile();
  }, [WebApp?.initDataUnsafe?.user?.id]);

  useEffect(() => {
    WebApp?.initDataUnsafe?.user?.id &&
      setDefaultParams({
        auth_date: WebApp?.initDataUnsafe?.auth_date,
        query_id: WebApp?.initDataUnsafe?.query_id,
        user: JSON.stringify(WebApp?.initDataUnsafe?.user),
        hash: WebApp?.initDataUnsafe?.hash,
      });
  }, [WebApp?.initDataUnsafe?.user?.id]);

  return (
    <ApplicationContext.Provider
      value={
        {
          profile,
          setProfile,
          WebApp,
          defaultParams,
          setDefaultParams,
          user,
          task,
          assets,
          setTask,
          handleGetProfile,
          ref,
          setUser,
          reward,
        } as any
      }
    >
      {children}
    </ApplicationContext.Provider>
  );
}
