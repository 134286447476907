import toast from "react-hot-toast";
import IconX from "../../assets/icon/IconX";
import Box from "../../components/Box";
import ButtonLink from "../../components/ButtonLink";
import ButtonTurn from "../../components/ButtonTurn";
import { useApplicationContext } from "../../contexts/useApplication";
import { checkTask } from "../../services/profile";
import ButtonCheck from "../../components/ButtonCheck";
import { TaskTypeEnum, X_TWEET_URL, X_URL } from "../../constant";
import CustomInput from "../../components/custom/CustomInput";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "antd";
import { TwitterShareButton } from "react-share";

const DailyMissions = () => {
  const { user, task, defaultParams, setTask } = useApplicationContext();
  const [loading, setLoading] = useState(false);
  const [loadingRef, setLoadingRef] = useState(false);
  const [value, setValue] = useState("");

  const handeCheckTask = async (type: any) => {
    if (
      (type == TaskTypeEnum.DAILY_TWEET_TAG && loading) ||
      (type == TaskTypeEnum.DAILY_REF && loadingRef)
    )
      return;

    if (!value && type == TaskTypeEnum.DAILY_TWEET_TAG)
      return toast.error("Enter Tweet URL");
    if (
      !value.includes("twitter.com") &&
      !value.includes("x.com") &&
      type == TaskTypeEnum.DAILY_TWEET_TAG
    )
      return toast.error("Invalid Tweet URL");
    try {
      switch (type) {
        case TaskTypeEnum.DAILY_TWEET_TAG:
          setLoading(true);
          break;
        case TaskTypeEnum.DAILY_REF:
          setLoadingRef(true);
          break;
      }
      const res = await checkTask({ type: type }, defaultParams);
      if (res?.data) {
        setTask(res.data);
        if (!res.data?.refDaily && type == TaskTypeEnum.DAILY_REF)
          return toast.error("You haven't invited anyone today!");
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || error?.message);
    } finally {
      switch (type) {
        case TaskTypeEnum.DAILY_TWEET_TAG:
          setLoading(false);
          break;
        case TaskTypeEnum.DAILY_REF:
          setLoadingRef(false);
          break;
      }
    }
  };
  return (
    <div>
      <Box>
        <div className="text-black px-5 text-[20px] py-3">
          <div className="flex items-center gap-1">
            Tweet a Tweet that includes hashtags{" "}
            <div className="text-[#ff0000]">#xaitama</div>
          </div>
          <CustomInput
            onChange={(e: any) => setValue(e.target.value)}
            value={value}
            className={`w-[169.5px] my-2 ${task?.tweetDaily && "invisible"}`}
            placeholder="Please enter Tweet URL"
          />
          <div className="flex justify-between items-end">
            <ButtonTurn isCompleted={task?.tweetDaily}></ButtonTurn>
            {task?.tweetDaily ? (
              <img src={"/images/task_completed.png"} alt="header" />
            ) : (
              <div className="flex items-center gap-1">
                <ButtonCheck
                  loading={loading}
                  onClick={() => handeCheckTask(TaskTypeEnum.DAILY_TWEET_TAG)}
                ></ButtonCheck>
                <div className="flex actionButton justify-center relative">
                  <img
                    src={"/images/btn.png"}
                    className="cursor-pointer"
                    alt="header"
                  />
                  <div
                    className={`absolute h-full z-10 text-[18px] uppercase w-full flex justify-center items-center m-auto inset-0 pb-1`}
                  >
                    <TwitterShareButton
                      url={"https://t.me/xaitama_bot\n"}
                      title={` Join Xaitama Game Now `}
                      hashtags={["xaitama"]}
                    >
                      <div className="btn-secondary space-x-2 px-4 flex items-center">
                        <IconX />
                        <span>Tweet</span>
                      </div>
                    </TwitterShareButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Box>
      <div className="text-[#E2684F] text-[16px] superLegendBoy font-bold text-center mt-10 mb-2">
        MORE FRIENDS, MORE FUN
      </div>
      <div className="flex items-center gap-4">
        <img
          src={"/images/saitamaxgenos.png"}
          className="cursor-pointer"
          alt="daily"
        />
        <div className="text-[20px] w-full">
          <div>
            <div>Invite Genos to enjoy</div>
            <div> hot pot together</div>
          </div>
          <div className="bg-[url('/public/images/btn_invite.png')] h-[30px] bg-cover bg-center bg-no-repeat pl-4 pt-1 my-2">
            {user?.refCode}
          </div>
          <div className="flex justify-between items-end">
            {/* <ButtonCheck
              loading={loadingRef}
              onClick={() => handeCheckTask(TaskTypeEnum.DAILY_REF)}
            ></ButtonCheck> */}
            <ButtonTurn isCompleted={task?.refDaily}></ButtonTurn>

            <CopyToClipboard
              text={`https://t.me/xaitama_bot?start=${user?.refCode}`}
            >
              <Tooltip title={"Copied"} trigger={["click"]}>
                <div className="flex actionButton check justify-center relative cursor-pointer">
                  <img src={"/images/btn.png"} alt="daily" />
                  <div
                    className={`absolute h-full z-10 text-[22px] w-full flex justify-center items-center m-auto inset-0 pb-1`}
                  >
                    Copy
                  </div>
                </div>
              </Tooltip>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DailyMissions;
