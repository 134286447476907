import toast from "react-hot-toast";
import IconDiscord from "../../assets/icon/IconDiscord";
import IconTele from "../../assets/icon/IconTele";
import IconX from "../../assets/icon/IconX";
import Box from "../../components/Box";
import ButtonCheck from "../../components/ButtonCheck";
import ButtonLink from "../../components/ButtonLink";
import ButtonTurn from "../../components/ButtonTurn";
import { DC_URL, TL_URL, TaskTypeEnum, X_URL } from "../../constant";
import { useApplicationContext } from "../../contexts/useApplication";
import { checkTask, claimXaita } from "../../services/profile";
import CustomInput from "../../components/custom/CustomInput";
import { useState } from "react";

const MainMissions = () => {
  const { task, setTask, defaultParams, ref } = useApplicationContext();
  const [loading, setLoading] = useState(false);
  const [loadingFollowX, setLoadingFollowX] = useState<any>(false);
  const [loadingDiscord, setLoadingDiscord] = useState<any>(false);
  const [loadingTele, setLoadingTele] = useState<any>(false);

  const [valueX, setValueX] = useState("");
  const [valueDC, setValueDC] = useState("");
  const [valueTL, setValueTL] = useState("");

  const handeCheckTask = async (type: any, input: any) => {
    // if (!input) return toast.error("Enter Textfield to Summit!");
    if (
      (type == TaskTypeEnum.FOLLOW_X && loadingFollowX) ||
      (type == TaskTypeEnum.JOIN_DC && loadingDiscord) ||
      (type == TaskTypeEnum.JOIN_TL && loadingTele)
    )
      return;
    try {
      switch (type) {
        case TaskTypeEnum.FOLLOW_X:
          setLoadingFollowX(true);
          break;
        case TaskTypeEnum.JOIN_DC:
          setLoadingDiscord(true);
          break;
        case TaskTypeEnum.JOIN_TL:
          setLoadingTele(true);
          break;
      }
      const res = await checkTask({ type: type }, defaultParams);
      if (res?.data) {
        setTask(res.data);
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || error?.message);
    } finally {
      switch (type) {
        case TaskTypeEnum.FOLLOW_X:
          setLoadingFollowX(false);
          break;
        case TaskTypeEnum.JOIN_DC:
          setLoadingDiscord(false);
          break;
        case TaskTypeEnum.JOIN_TL:
          setLoadingTele(false);
          break;
      }
    }
  };
  const handeClaimXaita = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const res = await claimXaita(defaultParams);
      if (res?.data) {
        setTask(res.data);
        toast.success("Claim $Xaita successfully!");
      } else toast.error("Something went wrong, try again later!");
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          error?.message ||
          "Something went wrong, try again later!"
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="grid grid-rows-4 gap-4">
      <Box>
        <div className="text-black px-5 text-[20px] py-3">
          <div className="flex items-center gap-1">Follow Twitter</div>
          <CustomInput
            onChange={(e: any) => setValueX(e.target.value)}
            value={valueX}
            className={`w-[169.5px] my-2 ${"invisible"}`}
            placeholder="Please enter TwitterId"
          />
          <div className="flex justify-between items-end">
            <ButtonTurn isCompleted={task?.followX}></ButtonTurn>
            {task?.followX ? (
              <img src={"/images/task_completed.png"} alt="header" />
            ) : (
              <div className="flex items-center gap-1">
                <ButtonCheck
                  loading={loadingFollowX}
                  onClick={() => handeCheckTask(TaskTypeEnum.FOLLOW_X, valueX)}
                ></ButtonCheck>
                <ButtonLink
                  url={
                    "https://twitter.com/intent/follow?screen_name=xaitamagame"
                  }
                >
                  <div className="flex items-center gap-1">
                    <IconX /> follow
                  </div>
                </ButtonLink>
              </div>
            )}
          </div>
        </div>
      </Box>
    
      <Box>
        <div className="text-black px-5 text-[20px] py-3">
          <div className="flex items-center gap-1">Join Telegram Announcement Channel</div>
          <CustomInput
            onChange={(e: any) => setValueTL(e.target.value)}
            value={valueTL}
            className={`w-[169.5px] my-2 ${"invisible"}`}
            placeholder="Please enter Username"
          />
          <div className="flex justify-between items-end">
            <ButtonTurn isCompleted={task?.joinTL}></ButtonTurn>
            {task?.joinTL ? (
              <img src={"/images/task_completed.png"} alt="header" />
            ) : (
              <div className="flex items-center gap-1">
                <ButtonCheck
                  loading={loadingTele}
                  onClick={() => handeCheckTask(TaskTypeEnum.JOIN_TL, valueTL)}
                ></ButtonCheck>
                <ButtonLink url={TL_URL}>
                  <div className="flex items-center gap-1">
                    <IconTele /> join
                  </div>
                </ButtonLink>
              </div>
            )}
          </div>
        </div>
      </Box>
      <Box>
        <div className="text-black px-5 text-[20px] py-3">
          <div className="flex items-center gap-1">Join Telegram Chat channel</div>
          <CustomInput
            onChange={(e: any) => setValueDC(e.target.value)}
            value={valueDC}
            className={`w-[169.5px] my-2 ${"invisible"}`}
            placeholder="Please enter DiscordId"
          />
          <div className="flex justify-between items-end">
            <ButtonTurn isCompleted={task?.joinDC}></ButtonTurn>
            {task?.joinDC ? (
              <img src={"/images/task_completed.png"} alt="header" />
            ) : (
              <div className="flex items-center gap-1">
                <ButtonCheck
                  loading={loadingDiscord}
                  onClick={() => handeCheckTask(TaskTypeEnum.JOIN_DC, valueDC)}
                ></ButtonCheck>
                <ButtonLink url={'https://t.me/xaitamachat'}>
                  <div className="flex items-center gap-1">
                    <IconDiscord /> join
                  </div>
                </ButtonLink>
              </div>
            )}
          </div>
        </div>
      </Box>
      <Box>
        <div className="text-black px-5 text-[20px] pt-2">
          <div className="flex items-center gap-1">
            Invite <div className="text-[#ff0000]">5 friends</div> to receive
            10,000 $XAITA
          </div>
          <div className="flex items-center gap-1">
            Invite <div className="text-[#ff0000]">10 friends</div> to receive
            15,000 $XAITA
          </div>
          <div className="flex items-center gap-1">
            Invite <div className="text-[#ff0000]">20 friends</div> to receive
            20,000 $XAITA
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="flex items-center gap-1">
              You invited{" "}
              <div className="text-[#ff0000]">{ref?.length} friends</div>
            </div>
            {ref?.length >= 5 && (
              <ButtonCheck
                title="Claim"
                loading={loading}
                onClick={() => handeClaimXaita()}
              ></ButtonCheck>
            )}
          </div>
        </div>
      </Box>
    </div>
  );
};
export default MainMissions;
