import { useEffect, useState } from "react";
import useBackButton from "../../hooks/useBackButton";
import CustomTable from "../../components/table";
import toast from "react-hot-toast";
import { useApplicationContext } from "../../contexts/useApplication";
import { getRank } from "../../services/profile";
import { isMobile } from "react-device-detect";

const Rank = () => {
  const { user, ref, assets } = useApplicationContext();
  const [tab, setTab] = useState("x");
  const [activeTabDate, setActiveTabDate] = useState<any>("week");
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [userLeaderboard, setUserLeaderboard] = useState<any>(0);
  const [userLeaderboardData, setUserLeaderboardData] = useState<any>(0);

  const tabList = [
    {
      id: "x",
      name: (
        <>
          $XAITA <br /> LEADERBOARD
        </>
      ),
      img: "/images/xaitama_coin.png",
    },
    {
      id: "i",
      name: (
        <>
          INVITE <br />
          LEADERBOARD
        </>
      ),
      img: "/images/invite.png",
    },
  ];
  const tabTimeList = [
    {
      id: "week",
      title: "this week",
    },
    {
      id: "month",
      title: "this month",
    },
    {
      id: "all",
      title: "all time",
    },
  ];
  //useBackButton();
  const tableColumn = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 40,
      render: (value: number, record: any, index: any) => (
        <div className="">{index + 1}</div>
      ),
    },
    {
      title: "Player Name",
      dataIndex: "username",
      key: "username",
      width: 100,
      render: (value: number, record: any, index: any) => <div>{value}</div>,
    },
    {
      title: tab == "x" ? "Received" : "Friends",
      dataIndex: tab == "x" ? "sum" : "count",
      key: "claim",
      width: 100,
      render: (value: number, record: any, index: any) => (
        <div className="flex flex-row items-center justify-start gap-[4px] white">
          <img
            className="relative w-[20px] h-[20px] object-cover"
            alt=""
            src={tab == "x" ? "/images/xaitama_coin.png" : "/images/genos.png"}
          />
          <div className="relative font-semibold inline-block shrink-0">
            {value} {tab == "x" ? "$XAITA" : "Genos"}
          </div>
        </div>
      ),
    },
  ];
  const handleGetListKey = async () => {
    try {
      setLoading(true);
      const res = await getRank(
        {
          type: `${tab}_${activeTabDate}`,
          limit: 100,
          page: 0,
        },
        {}
      );
      if (res?.data) {
        setTableData(res?.data || []);
        const userLead = res?.data?.findIndex(
          (x: any) => x.username == user?.username
        ) as any;
        setUserLeaderboard(userLead);
        const userLeadData = res?.data?.find(
          (x: any) => x.username == user?.username
        ) as any;
        setUserLeaderboardData(userLeadData);
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetListKey();
  }, [tab, activeTabDate]);
  return (
    <div
      className={`bg-[#B7F1FE] pt-16 pb-32 h-full ${
        !isMobile && "overflow-scroll"
      }`}
    >
      <div className="relative left-1/2 translate-x-[-50%] inline-block">
        {tab === "x" && <img src="/images/xaita_bg.png" alt="err" />}
        {tab === "i" && <img src="/images/invite_bg.png" alt="err" />}
        <div className="absolute top-0 w-full">
          {/* title */}
          <div className="flex items-center justify-center mt-[0.2rem] gap-[0.4rem]">
            {tabList.map((item) => (
              <button
                key={item.id}
                onClick={() => {
                  setTab(item.id);
                }}
                className="flex items-center gap-[3.3px] px-[0.7rem] py-[0.2rem] "
              >
                <img src={item.img} alt="err" />
                <p className="text-black font-[400] text-[8px] text-start superLegendBoy">
                  {item.name}
                </p>
              </button>
            ))}
          </div>
          {/* body */}
          <div className="p-4">
            <div className="relative bg-white w-full grid grid-cols-3 text-left text-[7px] font-[700]">
              {tabTimeList.map((item) => (
                <div
                  onClick={() =>
                    activeTabDate != item.id && setActiveTabDate(item.id)
                  }
                  className={`flex superLegendBoy flex-row items-center justify-center py-3 px-4 cursor-pointer uppercase ${
                    activeTabDate == item.id
                      ? "border-b-[3px] border-solid border-[#fd5757] text-[#fd5757]"
                      : "text-tomato"
                  }`}
                >
                  {item.title}
                </div>
              ))}
            </div>
            <CustomTable
              scroll={{ y: 370 }}
              columns={tableColumn}
              dataSource={tableData}
              loading={loading}
            />
            <div className="relative w-full flex flex-row items-start justify-center my-2 gap-[4px]">
              <div className="w-1 relative rounded-[50%] bg-[#9d8963] h-1" />
              <div className="w-1 relative rounded-[50%] bg-[#9d8963] h-1" />
              <div className="w-1 relative rounded-[50%] bg-[#9d8963] h-1" />
            </div>
            <div className="flex items-center justify-between  p-[7.25px] bg-[#fcaba1]">
              <div>{userLeaderboard == -1 ? "101+" : userLeaderboard + 1}</div>
              <div>
                {user?.username} {"(You)"}
              </div>
              <div className="flex flex-row items-center justify-start gap-[4px] white">
                <img
                  className="relative w-[20px] h-[20px] object-cover"
                  alt=""
                  src={
                    tab == "x"
                      ? "/images/xaitama_coin.png"
                      : "/images/genos.png"
                  }
                />
                <div className="relative font-semibold inline-block shrink-0">
                  {tab == "x" ? userLeaderboardData?.sum || "--" : ref?.length}{" "}
                  {tab == "x" ? "$XAITA" : "Genos"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rank;
