import { Link } from "react-router-dom";

const ButtonExplore = ({ children, url = "" }: any) => {
  return (
    <Link to={url}>
      <div className="flex actionButton justify-center relative">
        <img src={"/images/btn.png"} className="cursor-pointer" alt="header" />
        <div
          className={`absolute h-full z-10 text-[18px] uppercase w-full flex justify-center items-center m-auto inset-0 pb-1`}
        >
          {children}
        </div>
      </div>
    </Link>
  );
};
export default ButtonExplore;
