import { Outlet } from "react-router-dom";
import "./App.css";
import "./customantd.scss";
import Footer from "./components/Footer";
import { useApplicationContext } from "./contexts/useApplication";
import Header from "./components/Header";
import { useEffect, useState } from "react";

function App() {
  const { WebApp } = useApplicationContext();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    if (typeof WebApp?.expand !== "undefined") {
      WebApp.expand();
    }
  }, [WebApp]);
  return (
    <div id="main" className="relative max-w-[414px] mx-auto h-[100vh]">
      {isLoading ? (
        <div className="absolute inset-0 z-[999]">
          <img src="/images/loading.png" className="w-full h-full" alt="err" />
        </div>
      ) : (
        <>
          <Header />
          <Outlet />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
