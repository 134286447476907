import { Link, useLocation } from "react-router-dom";
import { useApplicationContext } from "../../contexts/useApplication";
import CountUp from "react-countup";
import { formatBigNumber } from "../../utils";
import { Tooltip } from "antd";
import { DOCS_URL } from "../../constant";

const Header = () => {
  const { profile, assets } = useApplicationContext();
  const menu = [
    {
      img: "/images/header/xai_token.png",
      amount: profile?.balance || 0,
      title: "$XAI",
    },
    {
      img: "/images/header/xaitama_token.png",
      amount: assets?.amount || 0,
      title: "$XAITA",
    },
    {
      img: "/images/header/power.png",
      amount: profile?.energy || 0,
      title: "Turn",
    },
  ];
  const location = useLocation();
  
  //${location.pathname == "/rank" && "hidden"}

  return (
    <div
      className={`grid grid-cols-7 absolute top-0 pt-3 z-10 w-full items-end bg-opacity-80 ${
        location.pathname != "/" && "bg-[#B7F1FE]"
      } 
      `}
    >
      {menu.map((item: any, index: any) => (
        <Tooltip title={item.title} trigger={["click", "hover"]}>
          <div key={index} className="flex justify-center relative col-span-2">
            <img src={item.img} className="cursor-pointer" alt="header" />
            <div
              className={`absolute h-full z-10 text-[22px] w-full flex justify-center items-center m-auto inset-0 pl-7 ${
                index == 2 && "pt-0.5"
              }`}
            >
              {item.amount <= 100000 ? (
                <CountUp
                  end={item.amount}
                  start={0}
                  duration={1}
                  style={{ fontSize: 20 }}
                />
              ) : (
                <div>{formatBigNumber(item.amount)}</div>
              )}
            </div>
          </div>
        </Tooltip>
      ))}
      <Link to={DOCS_URL} target={"_blank"} rel="noreferrer">
        <img
          src="/images/header/htp.png"
          className="actionButton pl-2"
          alt="err"
        />
      </Link>
    </div>
  );
};
export default Header;
