export const getBurnXaitaByLevel = (level: any) => {
  if (!level) return 100;
  return 100 * 2 ** level;
};

export const getEarnXaitaByLevel = (level: any) => {
  if (!level) return 1000;
  return 1000 + level * 500;
};

export const getXaitaBurned = (level: any) => {
  if (level <= 0) {
    return 0;
  }
  let xaitaBurn = 0;
  for (let i = 0; i < level; i++) {
    xaitaBurn += 100 * Math.pow(2, i);
  }

  return xaitaBurn;
};

const ranges = [
  { divider: 1e24, suffix: 'Sept' },
  { divider: 1e21, suffix: 'Sext' },
  { divider: 1e18, suffix: 'Quin' },
  { divider: 1e15, suffix: 'Quad' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'B' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'K' },
];

export const formatBigNumber = (n: any) => {
  if (!n) return 0;
  for (let i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      return (
        parseFloat(Number(n / ranges[i].divider || 0)?.toFixed(2)) +
        ranges[i].suffix
      );
    }
  }
  return n.toString();
};
