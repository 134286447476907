import { useEffect, useState } from "react";
import { claimXaitaPlay, playGame } from "../../services/profile";
import { useApplicationContext } from "../../contexts/useApplication";
import toast from "react-hot-toast";

const HomePage = () => {
  const { defaultParams, handleGetProfile, user, reward } =
    useApplicationContext();

  const [play, setPlay] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [canClaim, setCanClaim] = useState(false);
  const now = Number((new Date().getTime() / 1000)?.toFixed(0));
  const [lastMint, setLastMint] = useState(now);
  const [expectedMint, setExpectedMint] = useState<any>(null);
  const [countdown, setCountdown] = useState(7200);
  const [xaiEarned, setXaiEarned] = useState(0);

  useEffect(() => {
    if (expectedMint - now > 0) {
      setXaiEarned(
        Number(Math.floor(((now - lastMint) * reward?.amount) / 7200))
      );
    }
  }, [user?.lastMint, expectedMint, now, lastMint]);

  useEffect(() => {
    canClaim && reward?.status == 0 && handleClaimPlayGame();
  }, [canClaim, reward?.status]);

  useEffect(() => {
    isPlay && !play && onPlay();
  }, [isPlay, play]);

  useEffect(() => {
    if (user?.lastMint) {
      const expectedMinted = Number((user?.lastMint / 1000)?.toFixed(0)) + 7200;
      setLastMint(Number((user?.lastMint / 1000)?.toFixed(0)));
      setExpectedMint(expectedMinted);
      if (expectedMinted - now > 0) {
        setIsPlay(true);
        setCountdown(expectedMinted - now);
      }
      if (expectedMinted - now < 0) {
        !!expectedMinted && setCanClaim(true);
      }
    }
  }, [user?.lastMint]);

  const onPlay = () => {
    setPlay(true);
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 0) {
          setCanClaim(true);
          clearInterval(interval);
          setPlay(false);
          setIsPlay(false);
          setCountdown(7200);
          return prevCountdown;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);
  };

  const handlePlayGame = async () => {
    try {
      const res = await playGame(defaultParams);
      if (res?.data) {
        await handleGetProfile();
        onPlay();
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || error?.message);
    }
  };

  const handleClaimPlayGame = async () => {
    try {
      const res = await claimXaitaPlay(defaultParams);
      if (res?.data) {
        await handleGetProfile();
        setCanClaim(false);
      }
    } catch (error: any) {
      console.log(error.response?.data?.message || error?.message);
      // toast.error(error.response?.data?.message || error?.message);
    }
  };

  const timeCountdown = () => {
    const hours = Math.floor(countdown / 3600);
    const minutes = Math.floor((countdown % 3600) / 60);
    const seconds = countdown % 60;
    return (
      <>
        {hours.toString().padStart(2, "0")} :{" "}
        {minutes.toString().padStart(2, "0")} :{" "}
        {seconds.toString().padStart(2, "0")}
      </>
    );
  };

  return (
    <div
      style={{
        backgroundImage: play
          ? "url('/images/background_punch.png')"
          : "url('/images/background.png')",
      }}
      className="h-full pb-32 bg-cover flex flex-col justify-center bg-center bg-no-repeat"
    >
      {play ? (
        <div>
          <div className="relative">
            <img
              src="/images/saitama_punch.gif"
              className="App-logo"
              alt="err"
            />
            <img
              className="absolute right-28 top-12 w-16"
              src="/images/coin_up.gif"
            />
          </div>
          <div className="absolute top-16 right-4">
            <div className="flex justify-center relative">
              <img
                src="/images/header/xaitama_token.png"
                className="cursor-pointer"
                alt="header"
              />
              <div
                className={`absolute h-full z-10 text-[22px] w-full flex justify-center items-center m-auto inset-0 pl-7`}
              >
                {xaiEarned}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <img
            src={"/images/saitama_hotpot.gif"}
            className="App-logo"
            alt="logo"
          />
          <div className="flex justify-between items-center mt-[-2.8rem]">
            <img src={"/images/cloud_left.png"} alt="cloud" />
            <img src={"/images/cloud_right.png"} alt="cloud" />
          </div>
        </div>
      )}

      <div className="flex flex-col items-center mt-[10px]">
        {/* Time */}
        <div className="relative">
          <img src={"/images/timer.png"} alt="cloud" />
          <p className="text-black text-[20px] superLegendBoy font-[400] absolute top-1/2 left-1/2 translate-x-[-50%] whitespace-nowrap translate-y-[-50%]">
            {timeCountdown()}
          </p>
        </div>

        {/* Button punch */}
        {!play && (
          <button
            className="punchButton mt-[10px] relative"
            onClick={() => {
              handlePlayGame();
            }}
          >
            <img
              src="/images/button_punch_bg.png"
              className="w-[256.13px]"
              alt="err"
            />
            <img
              src="/images/punch.gif"
              alt="err"
              className="w-[43.68px] h-[46px] absolute left-[8px] top-0"
            />
          </button>
        )}
      </div>
    </div>
  );
};
export default HomePage;
