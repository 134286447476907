import React, { useState } from "react";
import useBackButton from "../../hooks/useBackButton";
import { useApplicationContext } from "../../contexts/useApplication";
import DailyMissions from "../../containers/missions/daily";
import MainMissions from "../../containers/missions/main";
import { claimTurnTask } from "../../services/profile";
import toast from "react-hot-toast";

const Mission = () => {
  //useBackButton();
  const [activeTab, setActiveTab] = useState<any>("daily");
  const { defaultParams, setTask, handleGetProfile, task } =
    useApplicationContext();

  const handleClaimTurn = async () => {
    try {
      if (getAllTurn() == 0) return;
      const res = await claimTurnTask(defaultParams);
      if (res?.data) {
        toast.success("Claim turn successfully!");
        setTask(res.data);
        await handleGetProfile();
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || error?.message);
    }
  };
  const getTweetXTurn = () => {
    if (task?.tweetDaily && !task?.claimTweetDaily) return 1;
    return 0;
  };
  const getRefTurn = () => {
    if (task?.refDaily && !task?.claimRefDaily) return 1;
    return 0;
  };
  const getLikeXTurn = () => {
    if (task?.followX && !task?.claimFollowX) return 1;
    return 0;
  };
  const getDiscordTurn = () => {
    if (task?.joinDC && !task?.claimJoinDC) return 1;
    return 0;
  };
  const getTeleTurn = () => {
    if (task?.joinTL && !task?.claimJoinTL) return 1;
    return 0;
  };

  const getAllTurn = () => {
    return (
      getDiscordTurn() +
        getTweetXTurn() +
        getRefTurn() +
        getTeleTurn() +
        getLikeXTurn() || 0
    );
  };

  return (
    <div className="h-full bg-[#B7F1FE] text-black pb-32 pt-20 px-4 flex flex-col scrollbar-custom items-center overflow-scroll">
      <div className="relative bg-white w-full grid grid-cols-2 text-left text-[12px] font-[700]">
        <div
          onClick={() => activeTab != "daily" && setActiveTab("daily")}
          className={`flex superLegendBoy flex-row items-center justify-center py-3 px-3 cursor-pointer ${
            activeTab == "daily"
              ? "border-b-[3px] border-solid border-[#fd5757] text-[#fd5757]"
              : "text-tomato"
          }`}
        >
          DAILY MISSION
        </div>
        <div
          onClick={() => activeTab != "main" && setActiveTab("main")}
          className={`flex flex-row superLegendBoy items-center justify-center py-3 px-3 cursor-pointer ${
            activeTab == "main"
              ? "border-b-[3px] border-solid border-[#0493e2] text-[#0493e2]"
              : "text-cornflowerblue"
          }`}
        >
          MAIN MISSION
        </div>
      </div>
      <div className="w-full flex justify-between items-end my-4">
        <button
          className="punchButton"
          onClick={() => {
            handleClaimTurn();
          }}
        >
          <img src="/images/btn_claim.png" alt="err" />
        </button>
        <div className="flex justify-center relative">
          <img
            src="/images/header/power.png"
            className="cursor-pointer"
            alt="header"
          />
          <div
            className={`absolute h-full z-10 text-[22px] w-full flex justify-center items-center m-auto inset-0 pl-7`}
          >
            {getAllTurn()}
          </div>
        </div>
      </div>
      {activeTab == "daily" && <DailyMissions />}
      {activeTab == "main" && <MainMissions />}
    </div>
  );
};

export default Mission;
