const ButtonTurn = ({ children, isCompleted = false, turn = 1}: any) => {
  return (
    <div className="flex justify-center relative">
              <img
                src={isCompleted ? "/images/point.png" : "/images/point_completed.png"}
                alt="daily"
              />
              <div
                className={`absolute h-full z-10 text-[22px] w-full flex justify-center items-center m-auto inset-0 pt-1.5 pl-6`}
              >
                +{turn}
              </div>
            </div>
  );
};
export default ButtonTurn;
