export enum TaskTypeEnum {
    DAILY_TWEET_TAG,
    DAILY_REF,
    FOLLOW_X,
    JOIN_DC,
    JOIN_TL,
    X,
    REF_5,
    REF_10,
    REF_20,
  }

  export const X_URL = "https://twitter.com/xaitamagame"
  export const X_TWEET_URL = "https://twitter.com/xaitamagame"
  export const DC_URL = "https://discord.gg/nu79hvRA"
  export const TL_URL = "https://t.me/xaitamagame"
  export const DOCS_URL = "https://xaitama-game.gitbook.io/xaitama-game-docs/welcome-to-xaitama-game/introduction"