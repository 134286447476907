const NoData = ({
  description = "OH NO! NO HOTPOT LEFT? PLEASE TRY AGAIN",
}: any) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center text-[18px] font-semibold h-full w-full justify-center">
        <img src={"/images/nodata.png"} alt="no data" />
        <p className="text-black mt-4">{description}</p>
      </div>
    </div>
  );
};

export default NoData;
