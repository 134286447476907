import React, { ComponentPropsWithRef, HtmlHTMLAttributes } from "react";

interface ICustomInput extends ComponentPropsWithRef<"input"> {
  className?: string;
}

const CustomInput = ({ className, ...props }: ICustomInput) => {
  return (
    <div className={`${className} relative flex items-center `}>
      <img
        src="/images/input_bg.png"
        alt="err"
        className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-full"
      />
      <input
        {...props}
        className="outline-none placeholder:text-gray-700 w-full relative px-[10px] bg-transparent text-[16px] font-[400]"
      />
    </div>
  );
};

export default CustomInput;
