import React, { useState } from "react";
import useBackButton from "../../hooks/useBackButton";
import LevelBoard from "./LevelBoard";
import ModalLevelUp from "./ModalLevelUp";
import { useApplicationContext } from "../../contexts/useApplication";
import { formatBigNumber, getBurnXaitaByLevel } from "../../utils";
import { levelUp } from "../../services/profile";
import toast from "react-hot-toast";
import { isMobile } from "react-device-detect";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "antd";
import CustomTable from "../../components/table";

const Profile = () => {
  //useBackButton();
  const { assets, user, defaultParams, handleGetProfile, ref } =
    useApplicationContext();
  const [tab, setTab] = useState("x");

  const [isShowModal, setIsShowModal] = useState(false);
  const tabList = [
    {
      id: "x",
      name: (
        <>
          MY <br /> PROFILE
        </>
      ),
      img: "/images/xaitama_coin.png",
    },
    {
      id: "i",
      name: (
        <>
          MY <br />
          genos
        </>
      ),
      img: "/images/invite.png",
    },
  ];

  const handleLevelUp = async () => {
    try {
      const res = await levelUp(defaultParams);
      if (res?.data) {
        await handleGetProfile();
        setIsShowModal(true);
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || error?.message);
    }
  };
  const tableColumn = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 40,
      render: (value: number, record: any, index: any) => (
        <div className="">{index + 1}</div>
      ),
    },
    {
      title: "Player Name",
      dataIndex: "username",
      key: "username",
      width: 100,
      render: (value: number, record: any, index: any) => <div>{value}</div>,
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: 100,
      render: (value: number, record: any, index: any) => <div>{value}</div>,
    },
  ];
  return (
    <div
      className={`bg-[#B7F1FE] pt-16 pb-32 h-full ${
        !isMobile && "overflow-scroll"
      }`}
    >
      <div className="relative left-1/2 translate-x-[-50%] inline-block">
        {tab === "x" && <img src="/images/xaita_bg.png" alt="err" />}
        {tab === "i" && <img src="/images/invite_bg.png" alt="err" />}
        <div className="absolute top-0 w-full">
          {/* title */}
          <div className="flex items-center justify-evenly mt-[0.2rem]">
            {tabList.map((item) => (
              <button
                key={item.id}
                onClick={() => {
                  setTab(item.id);
                }}
                className="flex items-center gap-[10px] px-[0.7rem] py-[0.2rem] "
              >
                <img src={item.img} alt="err" />
                <p className="text-black font-[400] text-[8px] text-start superLegendBoy uppercase">
                  {item.name}
                </p>
              </button>
            ))}
          </div>
          {/* body */}
          <div className="p-4">
            {tab === "x" && (
              <div className="flex justify-center items-center flex-col">
                <ModalLevelUp
                  onCancel={() => {
                    setIsShowModal(false);
                  }}
                  open={isShowModal}
                />

                <LevelBoard />

                <div className="mt-2 text-center">
                  <p>Amount of $XAITA to next level</p>
                  <div className="relative flex justify-center">
                    <img src="/images/amount_bg.png" alt="err" className="" />
                    <p className="absolute top-1/2 pl-6 translate-y-[-50%] left-1/2 translate-x-[-50%]">
                      {formatBigNumber(assets?.amount)}/
                      {formatBigNumber(getBurnXaitaByLevel(user?.level))}
                    </p>
                  </div>
                </div>

                {Number(assets?.amount) > getBurnXaitaByLevel(user?.level) && (
                  <button
                    className="punchButton mt-1 relative"
                    onClick={() => {
                      handleLevelUp();
                    }}
                  >
                    <img src="/images/btn-level-up.png" alt="err" />
                    <img
                      className="absolute right-0 -top-3 w-5"
                      src="/images/level_up.gif"
                      alt="level_up"
                    />
                  </button>
                )}
              </div>
            )}
            {tab == "i" && (
              <div>
                <div>
                  <div>You received referral code from:</div>
                </div>
                <div className="bg-[url('/public/images/btn_invite.png')] w-1/2 h-[24px] bg-cover bg-center bg-no-repeat pl-4 pt-1 my-2">
                  {user?.inviteCode}
                </div>
                <div>
                  <div>Your code:</div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="bg-[url('/public/images/btn_invite.png')] w-1/2 h-[24px] bg-cover bg-center bg-no-repeat pl-4 pt-1 my-2">
                    {user?.refCode}
                  </div>
                  <CopyToClipboard
                    text={`https://t.me/xaitama_bot?start=${user?.refCode}`}
                  >
                    <Tooltip title={"Copied"} trigger={["click"]}>
                      <div className="flex actionButton check justify-center relative cursor-pointer">
                        <img src={"/images/btn.png"} alt="daily" />
                        <div
                          className={`absolute h-full z-10 text-[22px] w-full flex justify-center items-center m-auto inset-0 pb-1`}
                        >
                          Copy
                        </div>
                      </div>
                    </Tooltip>
                  </CopyToClipboard>
                </div>
                <div className="w-full relative box-border h-px border-t-[1px] border-dashed border-[#e5c06c] mt-2" />
                <div className="items-center flex gap-2 mt-2">
                  <img src={"/images/invite.png"} alt="err" />
                  <div className="flex flex-col items-start justify-start">
                    <div className="relative text-[12px] font-bold superLegendBoy">
                      GENO COMES TO YOUR HOME
                    </div>
                    <div className="relative text-[18px]">
                      List of friends who received your referral code
                    </div>
                  </div>
                </div>
                <CustomTable
                  scroll={{ y: 300 }}
                  columns={tableColumn}
                  dataSource={ref}
                  // loading={loading}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
